import type {
    DashboardIslamicCenter,
    DashboardIslamicCentersData, IslamicCenterEidAlAdhaPrayerTiming, IslamicCenterEidAlFitrPrayerTiming,
    IslamicCenterFullTimeSchoolTypeByGenders, IslamicCenterJummahPrayerTiming, IslamicCenterPrayerTiming,
    IslamicCenterQuota,
    IslamicCenterReportType, MainIslamicCenterData, MainIslamicCentersData
} from "~/types/islamic_centers";

export const useIslamicCentersDataService = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const baseUrl = useRuntimeConfig().public.baseApiUrl;

    const fetchIslamicCenterFullTimeSchoolTypeByGenders = async () => {
        const {data, error} = await useAsyncData('islamic-centers-full-time-school-type-by-genders',
            () => nuxtApp.$getIslamicCenterFullTimeSchoolTypeByGenders()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as IslamicCenterFullTimeSchoolTypeByGenders[];
        }
    }

    const fetchIslamicCenterQuota = async () => {
        const {data, error} = await useAsyncData('islamic-center-quota',
            () => nuxtApp.$getIslamicCenterQuota()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as IslamicCenterQuota[];
        }
    }

    const fetchIslamicCenterReportTypes = async () => {
        const {data, error} = await useAsyncData('islamic-center-report-type',
            () => nuxtApp.$getIslamicCenterReportTypes()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as IslamicCenterReportType[];
        }
    }

    /* Islamic Center Dashboard Data */
    const fetchIslamicCentersDataForDashboardByFilter = async () => {
        const {data, error, refresh} = await useAsyncData(`${route.query.filter ?? 'active'}-islamic-centers`,
            () => useNuxtApp().$getMyIslamicCentersByFilter((route.query.filter as string) ?? 'active')
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const filteredIslamicCentersData = computed<DashboardIslamicCentersData>(() => {
                return {
                    islamic_centers: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                    statistics: data.value.statistics,
                }
            });

            return ref({
                filteredIslamicCentersData,
                refresh
            });
        }
    }

    const fetchIslamicCentersDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getIslamicCentersDataFromUrl(url);
            return {
                islamic_centers: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
                statistics: response.statistics,
            } as DashboardIslamicCentersData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };
    /* Islamic Center Dashboard Data End */

    /* Dashboard Islamic Center */
    const fetchIslamicCenterResourceForDashboard = async (slug: string) => {
        const {data, error} = await useAsyncData('dashboard-islamic-center',
            () => nuxtApp.$getMyIslamicCenterBySlug(slug)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as DashboardIslamicCenter
        }
    };

    const requestToCreateNewIslamicCenter = async (data: object) => {
        try {
            const response = await nuxtApp.$createIslamicCenter(data);
            return response.data as DashboardIslamicCenter;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateIslamicCenter = async (data: object) => {
        try {
            const response = await nuxtApp.$updateIslamicCenterBySlug(route.params.slug as string, data);
            return response.data as DashboardIslamicCenter;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeactivateCurrentDashboardIslamicCenter = async () => {
        try {
            const response = await nuxtApp.$deactivateMyIslamicCenterBySlug(route.params.slug as string);
            return response.data as DashboardIslamicCenter;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToSubmitCurrentDashboardIslamicCenterForApproval = async () => {
        try {
            const response = await nuxtApp.$submitMyIslamicCenterForApprovalBySlug(route.params.slug as string);
            return response.data as DashboardIslamicCenter;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard Islamic Center End*/

    /*Dashboard Islamic Center Images*/
    const fetchCurrentDashboardIslamicCenterImages = async () => {
        const {data, error} = await useAsyncData('dashboard-islamic-center-images',
            () => nuxtApp.$getMyIslamicCenterImagesByIslamicCenterSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as string[];
        }
    };

    const requestToDeleteCurrentDashboardIslamicCenterImageByIndex = async (index: number) => {
        try {
            const response = await nuxtApp.$deleteMyIslamicCenterImageByIslamicCenterImageIndex(route.params.slug as string, index);
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /*Dashboard Islamic Center Images End*/

    const fetchMainIslamicCenter = async () => {
        const {data, error} = await useAsyncData(`classified-ad-${route.params.slug}`,
            () => nuxtApp.$getIslamicCenterBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as MainIslamicCenterData;
        }
    }

    const requestToCreateNewIslamicCenterReport = async (data: object) => {
        try {
            const response = await nuxtApp.$createIslamicCenterReport(data);
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchMainIslamicCentersData = async () => {
        const {data, error, refresh} = await useAsyncData(`main-islamic-centers`,
            () => useNuxtApp().$getIslamicCentersDataFromUrl(`${baseUrl}/islamic-centers`)
        );

        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed(() => {
                return {
                    islamic_center_page: {
                        islamic_centers: data.value.islamic_centers,
                        page_number: data.value.islamic_centers.meta.current_page,
                    },
                    pagination_meta_data: data.value.islamic_centers.meta,
                    pagination_links: data.value.islamic_centers.links,
                } as MainIslamicCentersData
            });

        }
    };

    const fetchMainIslamicCentersDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getIslamicCentersDataFromUrl(url);
            return {
                islamic_center_page: {
                    islamic_centers: response.islamic_centers,
                    page_number: response.islamic_centers.meta.current_page,
                },
                pagination_meta_data: response.islamic_centers.meta,
                pagination_links: response.islamic_centers.links,
            } as MainIslamicCentersData;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    /* Prayer Timing */
    const requestToUpdateIslamicCenterPrayerTiming = async (data: object) => {
        try {
            const response = await nuxtApp.$updateIslamicCenterPrayerTimingBySlug(route.params.slug as string, data);
            return response.data as IslamicCenterPrayerTiming;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /* Prayer Timing Ends */

    /* Jummah Prayer Timings */
    const requestToCreateIslamicCenterJummahPrayerTiming = async (data: object) => {
        try {
            const response = await nuxtApp.$createIslamicCenterJummahPrayerTiming(route.params.slug as string, data);
            return response.data as IslamicCenterJummahPrayerTiming;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateIslamicCenterJummahPrayerTiming = async (jummahPrayerTiming:IslamicCenterJummahPrayerTiming, data: object) => {
        try {
            const response = await nuxtApp.$updateIslamicCenterJummahPrayerTiming(route.params.slug as string, jummahPrayerTiming.id as string, data);
            return response.data as IslamicCenterJummahPrayerTiming;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeleteIslamicCenterJummahPrayerTiming = async (jummahPrayerTiming: IslamicCenterJummahPrayerTiming) => {
        try {
            const response = await nuxtApp.$deleteIslamicCenterJummahPrayerTiming(route.params.slug as string, jummahPrayerTiming.id);
            console.log(response);
            return response.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /* Jummah Prayer Timings End*/

    /* Eid Al Adha Timings */
    const requestToCreateIslamicCenterEidAlAdhaPrayerTiming = async (data: object) => {
        try {
            const response = await nuxtApp.$createIslamicCenterEidAlAdhaPrayerTiming(route.params.slug as string, data);
            return response.data as IslamicCenterEidAlAdhaPrayerTiming;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateIslamicCenterEidAlAdhaPrayerTiming = async (eidAlAdhaPrayerTiming:IslamicCenterEidAlAdhaPrayerTiming, data: object) => {
        try {
            const response = await nuxtApp.$updateIslamicCenterEidAlAdhaPrayerTiming(route.params.slug as string, eidAlAdhaPrayerTiming.id as string, data);
            return response.data as IslamicCenterEidAlAdhaPrayerTiming;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeleteIslamicCenterEidAlAdhaPrayerTiming = async (eidAlAdhaPrayerTiming: IslamicCenterEidAlAdhaPrayerTiming) => {
        try {
            const response = await nuxtApp.$deleteIslamicCenterEidAlAdhaPrayerTiming(route.params.slug as string, eidAlAdhaPrayerTiming.id);
            console.log(response);
            return response.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /* Eid Al Adha Timings End*/

    /* Eid Al Fitr Timings */
    const requestToCreateIslamicCenterEidAlFitrPrayerTiming = async (data: object) => {
        try {
            const response = await nuxtApp.$createIslamicCenterEidAlFitrPrayerTiming(route.params.slug as string, data);
            return response.data as IslamicCenterEidAlFitrPrayerTiming;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateIslamicCenterEidAlFitrPrayerTiming = async (eidAlFitrPrayerTiming:IslamicCenterEidAlFitrPrayerTiming, data: object) => {
        try {
            const response = await nuxtApp.$updateIslamicCenterEidAlFitrPrayerTiming(route.params.slug as string, eidAlFitrPrayerTiming.id as string, data);
            return response.data as IslamicCenterEidAlFitrPrayerTiming;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeleteIslamicCenterEidAlFitrPrayerTiming = async (eidAlFitrPrayerTiming: IslamicCenterEidAlFitrPrayerTiming) => {
        try {
            const response = await nuxtApp.$deleteIslamicCenterEidAlFitrPrayerTiming(route.params.slug as string, eidAlFitrPrayerTiming.id);
            console.log(response);
            return response.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /* Eid Al Fitr Timings End*/


    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchIslamicCenterFullTimeSchoolTypeByGenders,
        fetchIslamicCenterQuota,
        fetchIslamicCenterReportTypes,

        fetchIslamicCentersDataForDashboardByFilter,
        fetchIslamicCentersDataForDashboardFromUrl,
        fetchIslamicCenterResourceForDashboard,
        requestToCreateNewIslamicCenter,
        requestToUpdateIslamicCenter,
        requestToDeactivateCurrentDashboardIslamicCenter,
        requestToSubmitCurrentDashboardIslamicCenterForApproval,

        fetchCurrentDashboardIslamicCenterImages,
        requestToDeleteCurrentDashboardIslamicCenterImageByIndex,

        fetchMainIslamicCenter,

        requestToCreateNewIslamicCenterReport,

        fetchMainIslamicCentersData,
        fetchMainIslamicCentersDataFromUrl,

        requestToUpdateIslamicCenterPrayerTiming,

        requestToCreateIslamicCenterJummahPrayerTiming,
        requestToUpdateIslamicCenterJummahPrayerTiming,
        requestToDeleteIslamicCenterJummahPrayerTiming,

        requestToCreateIslamicCenterEidAlAdhaPrayerTiming,
        requestToUpdateIslamicCenterEidAlAdhaPrayerTiming,
        requestToDeleteIslamicCenterEidAlAdhaPrayerTiming,

        requestToCreateIslamicCenterEidAlFitrPrayerTiming,
        requestToUpdateIslamicCenterEidAlFitrPrayerTiming,
        requestToDeleteIslamicCenterEidAlFitrPrayerTiming,

    }
}