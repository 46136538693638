import type {
    IslamicCenterEidAlAdhaPrayerTiming,
    IslamicCenterEidAlFitrPrayerTiming,
    IslamicCenterJummahPrayerTiming
} from "~/types";

export const useIslamicCentersDataManager = () => {
    const route = useRoute();
    const islamicCentersStore = useIslamicCenters();
    const islamicCentersDataService = useIslamicCentersDataService();

    const getIslamicCenterFullTimeSchoolTypeByGenders = async () => {
        const storedIslamicCenterFullTimeSchoolTypeByGenders = computed(() => {
            return islamicCentersStore.islamic_centers_full_time_school_type_by_gender;
        })
        if (storedIslamicCenterFullTimeSchoolTypeByGenders.value == null || storedIslamicCenterFullTimeSchoolTypeByGenders.value.length == 0 || !islamicCentersStore.isIslamicCenterFullTimeSchoolTypeByGendersValid()) {
            const islamicCenterFullTimeSchoolTypeByGenders =  await islamicCentersDataService.fetchIslamicCenterFullTimeSchoolTypeByGenders();
            if(islamicCenterFullTimeSchoolTypeByGenders)
                islamicCentersStore.setIslamicCenterFullTimeSchoolTypeByGenders(islamicCenterFullTimeSchoolTypeByGenders);
        }

        return storedIslamicCenterFullTimeSchoolTypeByGenders;
    }

    const getIslamicCenterQuota = async () => {
        const storedIslamicCenterQuota = computed(() => {
            return islamicCentersStore.islamic_center_quota;
        })
        if (storedIslamicCenterQuota.value == null || !islamicCentersStore.isIslamicCenterQuotaValid()) {
            const islamicCenterQuota = await islamicCentersDataService.fetchIslamicCenterQuota();
            if(islamicCenterQuota)
                islamicCentersStore.setIslamicCenterQuota(islamicCenterQuota);
        }

        return storedIslamicCenterQuota.value;
    }

    const getIslamicCenterReportTypes = async () => {
        const storedIslamicCenterReportTypes = computed(() => {
            return islamicCentersStore.islamic_center_report_types;
        })
        if (storedIslamicCenterReportTypes.value == null || storedIslamicCenterReportTypes.value.length == 0 || !islamicCentersStore.isIslamicCenterReportTypesValid()) {
            const islamicCenterReportTypes =  await islamicCentersDataService.fetchIslamicCenterReportTypes();
            if(islamicCenterReportTypes)
                islamicCentersStore.setIslamicCenterReportTypes(islamicCenterReportTypes);
        }

        return storedIslamicCenterReportTypes;
    }

    /* Islamic Center Dashboard Data */

    const getDashboardIslamicCentersByFilter = async () => {
        const dashboardData = await islamicCentersDataService.fetchIslamicCentersDataForDashboardByFilter();
        return computed(() => dashboardData?.value)
    }

    const getDashboardIslamicCentersFromUrl = async (url: string) => {
        const results = await islamicCentersDataService.fetchIslamicCentersDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    /* Islamic Center Dashboard Data End */

    /* Dashboard Islamic Center */
    const getCurrentDashboardIslamicCenter = async () => {
        const storedIslamicCenter = computed(() => {
            return islamicCentersStore.current_dashboard_islamic_center;
        })

        const slug = route.params.slug;
        if ((storedIslamicCenter.value == null || storedIslamicCenter.value.id == null || storedIslamicCenter.value.slug != route.params.slug || !islamicCentersStore.isCurrentDashboardIslamicCenterValid()) && slug) {
            const islamicCenter = await islamicCentersDataService.fetchIslamicCenterResourceForDashboard(slug as string);
            if(islamicCenter)
                islamicCentersStore.setCurrentDashboardIslamicCenter(islamicCenter);
        }

        return storedIslamicCenter;
    }

    const createNewIslamicCenter = async (data: object) => {
        const createdIslamicCenter = await islamicCentersDataService.requestToCreateNewIslamicCenter(data);
        if (createdIslamicCenter) {
            islamicCentersStore.setCurrentDashboardIslamicCenter(createdIslamicCenter);
            useAlertSetter().setSuccessAlert("Islamic Center Listing Created Successfully");
            navigateTo({
                name: "dashboard-islamic-centers",
                query: {
                    filter: "active",
                }
            });
        }
    };

    const updateIslamicCenter = async (data: object) => {
        const updatedIslamicCenter = await islamicCentersDataService.requestToUpdateIslamicCenter(data);
        if(updatedIslamicCenter){
            islamicCentersStore.setCurrentDashboardIslamicCenter(updatedIslamicCenter);
            useAlertSetter().setSuccessAlert("Islamic Center Listing Updated Successfully");
            navigateTo({
                name: "dashboard-islamic-centers-slug",
                params: { slug: islamicCentersStore.current_dashboard_islamic_center?.slug as string },
            })
        }
    };

    const deactivateCurrentDashboardIslamicCenter = async () => {
        const deactivatedIslamicCenter = await islamicCentersDataService.requestToDeactivateCurrentDashboardIslamicCenter();
        if(deactivatedIslamicCenter){
            islamicCentersStore.setCurrentDashboardIslamicCenter(deactivatedIslamicCenter)
            navigateTo({
                name: "dashboard-islamic-centers-slug",
                params: {
                    slug: deactivatedIslamicCenter.slug
                }
            });
        }
    };

    const submitCurrentDashboardIslamicCenterForApproval = async () => {
        const submittedIslamicCenter = await islamicCentersDataService.requestToSubmitCurrentDashboardIslamicCenterForApproval();
        if(submittedIslamicCenter){
            islamicCentersStore.setCurrentDashboardIslamicCenter(submittedIslamicCenter);
            navigateTo({
                name: "dashboard-islamic-centers-slug",
                params: {
                    slug: submittedIslamicCenter.slug,
                },
            });
        }
    };
    /* Dashboard Islamic Center End*/

    /*Dashboard Islamic Center Images*/
    const getCurrentDashboardIslamicCenterImages = async () => {
        const storedIslamicCenterImages = computed(() => {
            return islamicCentersStore.current_dashboard_islamic_center_images;
        })
        if (storedIslamicCenterImages.value == null || storedIslamicCenterImages.value.length == 0 || !islamicCentersStore.isCurrentDashboardIslamicCenterImagesValid()) {
            const images = await islamicCentersDataService.fetchCurrentDashboardIslamicCenterImages();
            if(images)
                islamicCentersStore.setCurrentDashboardIslamicCenterImages(images);
        }
        return storedIslamicCenterImages;
    }

    const addImageToCurrentDashboardIslamicCenterImages = (imageUrl: string) => {
        const storedIslamicCenterImages = computed(() => {
            return islamicCentersStore.current_dashboard_islamic_center_images;
        })
        islamicCentersStore.addImageToCurrentDashboardIslamicCenterImages(imageUrl);
        useAlertSetter().setSuccessAlert("Successfully added image for islamic center listing");
        return storedIslamicCenterImages;
    }

    const removeImageFromCurrentDashboardIslamicCenterImagesAtIndex = async (index: number) => {
        const storedIslamicCenterImages = computed(() => {
            return islamicCentersStore.current_dashboard_islamic_center_images;
        })
        const results = await islamicCentersDataService.requestToDeleteCurrentDashboardIslamicCenterImageByIndex(index);
        if(results)
            islamicCentersStore.removeImageFromCurrentDashboardIslamicCenterImagesAtIndex(index);

        useAlertSetter().setSuccessAlert("Successfully removed image from islamic center listing");
        return storedIslamicCenterImages;
    }
    /*Dashboard Islamic Center Images End*/

    const getMainIslamicCenter = async () => {
        return await islamicCentersDataService.fetchMainIslamicCenter();
    }

    const reportIslamicCenter = async (data:object) => {
        const createdIslamicCenterReport = await islamicCentersDataService.requestToCreateNewIslamicCenterReport(data);
        if (createdIslamicCenterReport) {
            useAlertSetter().setSuccessAlert("Islamic Center Listing Reported Successfully");
            return createdIslamicCenterReport;
        }
        return null;
    }

    const getMainIslamicCenters = async () => {
        const results = await islamicCentersDataService.fetchMainIslamicCentersData();
        return results?.value;
    }

    const getMainClassifiedAdsFromUrl = async (url: string) => {
        return await islamicCentersDataService.fetchMainIslamicCentersDataFromUrl(url);
    }

    /* Prayer Timing */
    const updateIslamicCenterPrayerTiming = async (data: object) => {
        const updatedIslamicCenterPrayerTiming = await islamicCentersDataService.requestToUpdateIslamicCenterPrayerTiming(data);
        console.log(updatedIslamicCenterPrayerTiming);
        if(updatedIslamicCenterPrayerTiming){
            islamicCentersStore.setCurrentDashboardIslamicCenterPrayerTiming(updatedIslamicCenterPrayerTiming);
            useAlertSetter().setSuccessAlert("Islamic Center Prayer Timing Updated Successfully");
        }
    };
    /* Prayer Timing End */


    /* Eid Al Adha Prayer Timing */
    const createIslamicCenterEidAlAdhaPrayerTiming = async (data: object) => {
        const createdIslamicCenterEidAlAdhaPrayerTiming = await islamicCentersDataService.requestToCreateIslamicCenterEidAlAdhaPrayerTiming(data);
        if(createdIslamicCenterEidAlAdhaPrayerTiming){
            islamicCentersStore.addCurrentDashboardIslamicCenterEidAlAdhaPrayerTiming(createdIslamicCenterEidAlAdhaPrayerTiming);
            useAlertSetter().setSuccessAlert("Eid Al Adha Prayer Timing Created Successfully For Islamic Center");
            return createdIslamicCenterEidAlAdhaPrayerTiming;
        }
    };

    const updateIslamicCenterEidAlAdhaPrayerTiming = async (eidAlAdhaPrayerTiming: IslamicCenterEidAlAdhaPrayerTiming ,data: object) => {
        const updatedIslamicCenterEidAlAdhaPrayerTiming = await islamicCentersDataService.requestToUpdateIslamicCenterEidAlAdhaPrayerTiming(eidAlAdhaPrayerTiming,data);
        if(updatedIslamicCenterEidAlAdhaPrayerTiming){
            islamicCentersStore.updateCurrentDashboardIslamicCenterEidAlAdhaPrayerTiming(updatedIslamicCenterEidAlAdhaPrayerTiming);
            useAlertSetter().setSuccessAlert("Islamic Center Eid Al Adha Prayer Timing Updated Successfully");
            return updatedIslamicCenterEidAlAdhaPrayerTiming;
        }
    };
    const deleteIslamicCenterEidAlAdhaPrayerTiming = async (eidAlAdhaPrayerTiming: IslamicCenterEidAlAdhaPrayerTiming) => {
        const message = await islamicCentersDataService.requestToDeleteIslamicCenterEidAlAdhaPrayerTiming(eidAlAdhaPrayerTiming);
        if(message){
            islamicCentersStore.deleteCurrentDashboardIslamicCenterEidAlAdhaPrayerTiming(eidAlAdhaPrayerTiming);
            useAlertSetter().setSuccessAlert("Islamic Center Eid Al Adha Prayer Timing Deleted Successfully");
            return true;
        }
    };
    /* Eid Al Adha Prayer Timing End */

    /* Eid Al Fitr Prayer Timing */
    const createIslamicCenterEidAlFitrPrayerTiming = async (data: object) => {
        const createdIslamicCenterEidAlFitrPrayerTiming = await islamicCentersDataService.requestToCreateIslamicCenterEidAlFitrPrayerTiming(data);
        if(createdIslamicCenterEidAlFitrPrayerTiming){
            islamicCentersStore.addCurrentDashboardIslamicCenterEidAlFitrPrayerTiming(createdIslamicCenterEidAlFitrPrayerTiming);
            useAlertSetter().setSuccessAlert("Eid Al Fitr Prayer Timing Created Successfully For Islamic Center");
            return createdIslamicCenterEidAlFitrPrayerTiming;
        }
    };

    const updateIslamicCenterEidAlFitrPrayerTiming = async (eidAlFitrPrayerTiming: IslamicCenterEidAlFitrPrayerTiming ,data: object) => {
        const updatedIslamicCenterEidAlFitrPrayerTiming = await islamicCentersDataService.requestToUpdateIslamicCenterEidAlFitrPrayerTiming(eidAlFitrPrayerTiming,data);
        if(updatedIslamicCenterEidAlFitrPrayerTiming){
            islamicCentersStore.updateCurrentDashboardIslamicCenterEidAlFitrPrayerTiming(updatedIslamicCenterEidAlFitrPrayerTiming);
            useAlertSetter().setSuccessAlert("Islamic Center Eid Al Fitr Prayer Timing Updated Successfully");
            return updatedIslamicCenterEidAlFitrPrayerTiming;
        }
    };
    const deleteIslamicCenterEidAlFitrPrayerTiming = async (eidAlFitrPrayerTiming: IslamicCenterEidAlFitrPrayerTiming) => {
        const message = await islamicCentersDataService.requestToDeleteIslamicCenterEidAlFitrPrayerTiming(eidAlFitrPrayerTiming);
        if(message){
            islamicCentersStore.deleteCurrentDashboardIslamicCenterEidAlFitrPrayerTiming(eidAlFitrPrayerTiming);
            useAlertSetter().setSuccessAlert("Islamic Center Eid Al Fitr Prayer Timing Deleted Successfully");
            return true;
        }
    };
    /* Eid Al Fitr Prayer Timing End */

    /* Jummah Prayer Timing */
    const createIslamicCenterJummahPrayerTiming = async (data: object) => {
        const createdIslamicCenterJummahPrayerTiming = await islamicCentersDataService.requestToCreateIslamicCenterJummahPrayerTiming(data);
        if(createdIslamicCenterJummahPrayerTiming){
            islamicCentersStore.addCurrentDashboardIslamicCenterJummahPrayerTiming(createdIslamicCenterJummahPrayerTiming);
            useAlertSetter().setSuccessAlert("Jummah Prayer Timing Created Successfully For Islamic Center");
            return createdIslamicCenterJummahPrayerTiming;
        }
    };

    const updateIslamicCenterJummahPrayerTiming = async (jummahPrayerTiming: IslamicCenterJummahPrayerTiming ,data: object) => {
        const updatedIslamicCenterJummahPrayerTiming = await islamicCentersDataService.requestToUpdateIslamicCenterJummahPrayerTiming(jummahPrayerTiming,data);
        if(updatedIslamicCenterJummahPrayerTiming){
            islamicCentersStore.updateCurrentDashboardIslamicCenterJummahPrayerTiming(updatedIslamicCenterJummahPrayerTiming);
            useAlertSetter().setSuccessAlert("Islamic Center Jummah Prayer Timing Updated Successfully");
            return updatedIslamicCenterJummahPrayerTiming;
        }
    };
    const deleteIslamicCenterJummahPrayerTiming = async (jummahPrayerTiming: IslamicCenterJummahPrayerTiming) => {
        const message = await islamicCentersDataService.requestToDeleteIslamicCenterJummahPrayerTiming(jummahPrayerTiming);
        if(message){
            islamicCentersStore.deleteCurrentDashboardIslamicCenterJummahPrayerTiming(jummahPrayerTiming);
            useAlertSetter().setSuccessAlert("Islamic Center Jummah Prayer Timing Deleted Successfully");
            return true;
        }
    };
    /* Jummah Prayer Timing End */



    return {
        getIslamicCenterFullTimeSchoolTypeByGenders,
        getIslamicCenterQuota,
        getIslamicCenterReportTypes,

        getDashboardIslamicCentersByFilter,
        getDashboardIslamicCentersFromUrl,
        getCurrentDashboardIslamicCenter,
        createNewIslamicCenter,
        updateIslamicCenter,
        deactivateCurrentDashboardIslamicCenter,
        submitCurrentDashboardIslamicCenterForApproval,

        getCurrentDashboardIslamicCenterImages,
        addImageToCurrentDashboardIslamicCenterImages,
        removeImageFromCurrentDashboardIslamicCenterImagesAtIndex,

        getMainIslamicCenter,
        reportIslamicCenter,
        getMainIslamicCenters,
        getMainClassifiedAdsFromUrl,

        updateIslamicCenterPrayerTiming,

        createIslamicCenterJummahPrayerTiming,
        updateIslamicCenterJummahPrayerTiming,
        deleteIslamicCenterJummahPrayerTiming,


        createIslamicCenterEidAlAdhaPrayerTiming,
        updateIslamicCenterEidAlAdhaPrayerTiming,
        deleteIslamicCenterEidAlAdhaPrayerTiming,

        createIslamicCenterEidAlFitrPrayerTiming,
        updateIslamicCenterEidAlFitrPrayerTiming,
        deleteIslamicCenterEidAlFitrPrayerTiming,
    }
}